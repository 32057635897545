const tenantCustonConfig = {
  brandName: "BPN Pagos",
  dropdownMenuItems: ["bankAccount", "config", "buyReader", "staticQRCode"],
  currencyDecimals: true,
  stats: {
    statsEnabled: ["products", "staff", "customers"],
  },
  automaticCashout: {
    isEnabled: false,
  },
  termsAndConditions: false,
  paramNameSubsidiaryId: "subsidiaryId",
  sales: {
    transactionReceipt: {
      summary: [
        {
          label: "Titular de tarjeta",
          method: (sale) => sale.payment.cardHolderName,
        },
        {
          label: "Tasa comisión",
          method: (sale) => sale.commission + "%",
        },
        {
          label: "Fecha de disponibilidad",
          method: (sale) => sale.availableDate,
        },
        {
          label: "N° de referencia",
          method: (sale) => sale.referenceNumber,
        },
        {
          label: "Nº de autorización",
          method: (sale) => sale.authorizationCode,
        },
      ],
      qr: [
        {
          label: "Titular de tarjeta",
          method: (sale) => sale.payment.cardHolderName,
        },
        {
          label: "Tasa comisión",
          method: (sale) => sale.commission + "%",
        },
        {
          label: "Fecha de disponibilidad",
          method: (sale) => sale.availableDate,
        },
        {
          label: "N° de referencia",
          method: (sale) => sale.referenceNumber,
        },
        {
          label: "Nº de autorización",
          method: (sale) => sale.authorizationCode,
        },
      ],
    },
    extraItems: {
      taxAmount: {
        label: " ITBIS",
        value: (tr) => tr.taxAmount,
      },
      tipAmount: {
        label: "Tip Amount",
        value: (tr) => tr.tipAmount,
      },
    },
    options: {
      type: [
        { value: "", label: "transactions.optionVoid" },
        { value: "SALE", label: "transactions.types.SALE" },
        { value: "ANNULMENT", label: "transactions.types.ANNULMENT" },
        { value: "REFUND", label: "transactions.types.REFUND" },
      ],
    },
  },
};

const tenantTableColumns = {
  salesColumns: [
    "description",
    // "payment",
    "date",
    "amount",
    // "subsidiary",
  ],
};

export { tenantCustonConfig, tenantTableColumns };
